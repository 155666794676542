<template>
    <div>
        <el-row :gutter="20">
            <el-col>
                <el-card shadow="hover"
                         class="mgb20"
                         style="height:252px;">
                    <div class="user-info">
                        <img src="../assets/img/img.jpg"
                             class="user-avator"
                             alt />
                        <div class="user-info-cont">
                            <div class="user-info-name">{{ username }}</div>
                        </div>
                    </div>
                    <div class="user-info-list">
                        上次登入時間：
                        <span>{{login_at}}</span>
                    </div>

                </el-card>
            </el-col>
        </el-row>
        <div class="container"
             v-if="tableData.length>0">
            <h3>連續比對失敗域名來源</h3>
            <TableTemplate :colums="colums"
                           :data="tableData"></TableTemplate>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../components/TableTemplate.vue'
import { myInfo, syncDomainFailed } from '../api/index'
export default {
    name: 'dashboard',
    data() {
        return {
            login_at: '',
            colums: [
                {
                    name: '商戶',
                    prop: 'merchant_name',
                },
                {
                    name: '網域來源',
                    prop: 'source_name',
                },
                {
                    name: 'API KEY',
                    prop: 'key1',
                },
                {
                    name: '日期時間',
                    prop: 'updated_at',
                },
                {
                    name: '來源註記',
                    prop: 'remark',
                },
            ],
            tableData: '',
        }
    },
    components: { TableTemplate },
    computed: {
        username() {
            return this.$store.state.username
        },
    },
    mounted() {
        this.getInfo()
        this.getSyncDomainFailed()
    },
    methods: {
        getInfo() {
            myInfo().then((res) => {
                if (res.code == 300) {
                    this.$store.commit('getUserName', res.data.account)
                    this.login_at = res.data.login_at
                }
            })
        },
        getSyncDomainFailed() {
            syncDomainFailed().then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_list
                }
            })
        },
    },
}
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 100px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.user-info {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
}

.user-avator {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.user-info-cont {
    padding-left: 50px;
    flex: 1;
    font-size: 14px;
    color: #999;
}

.user-info-cont div:first-child {
    font-size: 30px;
    color: #222;
}

.user-info-list {
    font-size: 14px;
    color: #999;
    line-height: 25px;
}

.user-info-list span {
    margin-left: 70px;
}

.mgb20 {
    margin-bottom: 20px;
}

.todo-item {
    font-size: 14px;
}

.todo-item-del {
    text-decoration: line-through;
    color: #999;
}

.schart {
    width: 100%;
    height: 300px;
}
.container h3 {
    padding-bottom: 10px;
}
</style>
